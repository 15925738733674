<template>
  <v-container>
    <CatalogForm   v-if="tabNumber===1" :item="itemRow" @tabChange="loadTab" @setItemRow="itemRow = $event" class="ma-0 pa-0"/>
    <CatalogDtForm v-if="tabNumber===2" :item="itemRow" @tabChange="loadTab" @setItemRow="itemRow = $event" class="ma-0 pa-0"/>
  </v-container>
</template>
<script>
import CatalogForm from '@/components/CatalogForm.vue'
import CatalogDtForm from '@/components/CatalogDtForm.vue'
  export default {
    name: 'CatalogPre',
    props: {
      //
    },
    components: {
      CatalogForm, CatalogDtForm
    },
    data: () => ({
      //
      tabNumber: 1,
      itemRow: [],
    }),
    methods: {
      initialize: function () {
        //
      },
      loadTab: async function (val) {
        await this.scrollToTop();
        this.tabNumber = await  val;
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>

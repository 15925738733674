<template>
  <v-container>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''"
        >
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="btnClose()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-chevron-left
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>
    <v-card class="overflow-hidden ma-0 pa-0" flat>
      <!-- v-toolbar
        color="white" 
        flat dense
        >
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar -->
      <v-card-text class="ma-0 pa-0">
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_email" ref="text_email" v-on:keyup="keymonitor" :disabled="!isEditing"
                  hint="Usuario" persistent-hint
                  v-model="editedItem.email" label="Usuario"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_firstname" ref="text_firstname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.required]"
                  v-model="editedItem.firstname" label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_midname" ref="text_midname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.required]"
                  v-model="editedItem.midname" label="Paterno"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_lastname" ref="text_lastname" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.lastname" label="Materno"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_password" ref="text_password" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.password" label="Contraseña"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_movil" ref="text_movil" v-on:keyup="keymonitor" :disabled="!isEditing"
                  counter maxlength="12" v-mask="maskTel"
                  v-model="editedItem.movil" label="Movil"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_personalemail" ref="text_personalemail" v-on:keyup="keymonitor" :disabled="!isEditing"
                  :rules="[rules.email]"
                  v-model="editedItem.personalemail" label="Email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_comment" ref="text_comment" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.comment" label="Comentarios"></v-text-field>
              </v-col>
              <!-- v-col cols="12" sm="6" md="4">
                <v-select2 
                  class="style-chooser"
                  placeholder="Elija Termino CR"
                  v-model="editedItem.artrm_id" 
                  :options="optSelectartrm" 
                  :reduce="opt => opt.val" 
                  :filterable="true"
                  :disabled="!isEditing"
                  id="text_artrm_id" ref="text_artrm_id"
                  label="opt">
                  <div slot="no-options">No existe coincidencia!</div>
                </v-select2>
              </v-col -->
              <!-- v-col cols="12" sm="6" md="4">
                <v-text-field id="text_zipCode" ref="text_zipCode" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-mask="maskCP" v-model="editedItem.zipCode" label="CP"></v-text-field>
              </v-col -->
              <v-col cols="12" sm="6" md="4">
                <v-select id="text_active" ref="text_active" 
                  v-model="editedItem.active"
                  label="Es Activo?"
                  v-on:keyup="keymonitor"
                  :items="selectItemYN"
                  :disabled="!isEditing"
                  item-text="item"
                  item-value="val"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'CatalogDtForm',
    props: [
      'item'
    ],
    components: {
      //
    },
    data: () => ({
      searchValue: null,
      showFind: false,
      //
      showBtnSave: false,
      showBtnCancel: false,
      showBtnEdit: true,
      showBtnFind: false,
      showBtnPrint: true,
      showBtnNew: false,
      showBtnClose: true,
      //
      // *** title: 'Editar datos de usuario',
      titleLength: 0,
      maskTel: '##-####-####',
      maskCP: '#######',
      isEditing: null,
      editedItem: [
      ],
      optSelectartrm: [
        {opt: 'Leyendo', val: 1}
      ],
      selectItemYN: [
        { item: 'Si', val: '1' },
        { item: 'No', val: '0' }
      ],
      rules: {
        required: value => !!value || 'Campo Requerido.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
        }
      },
    }),
    methods: {
      initialize: function () {
        //
      },
      btnPrint: async function () {
        //
      },
      btnClose: async function () {
        this.$emit('setItemRow', []);
        this.$emit('tabChange', 1);
      },
      btnNew: async function () {
        //
      },
      btnEdit: async function () {
        // *** this.title = this.title.substring(0,this.titleLength);
        // *** this.title = this.title + ' (EDITANDO REGISTRO)';
        this.showBtnPrint = false;
        this.showBtnEdit = false;
        this.showBtnCancel = true;
        this.showBtnSave = true;
        this.editFocus('text_email');
      },
      btnCancel: async function () {
        this.showBtnPrint = true;
        this.showBtnEdit = true;
        this.isEditing = false;
        this.showBtnCancel = false;
        this.showBtnSave = false;
        // *** this.title = this.title.substring(0,this.titleLength);
        this.editedItem = this.copyObjects(this.item);
      },
      btnSave: async function () {
        this.showBtnSave = await false;
        this.showBtnCancel = await false;
        this.showBtnPrint = await true;
        this.showBtnEdit = await true;
        /*
        if(await this.validationFields()){
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          let formData = await this.editedItem;
          let apiRoute = await formData.id === 0? 'usercreate' : 'userupdate';
          formData.employee_id = await this.userData.id;
          formData.name = (await formData.firstname + ' ' + 
          await formData.midname + ' ' + 
          await formData.lastname).trim();
          if(await formData.password.length === 0 &&
            await formData.id === 0){
            formData.password = await this.makeString(6);
          }
          const returnData = await true;
          if(await apiRoute.trim().length !== 0){
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // *** console.log(dataResponse);
            if (await dataResponse) {
              if (await dataResponse.data){
                let message = await '';
                let type =  await 'info';
                if(await dataResponse.error === 0){
                  this.isEditing = await false;
                  if(await dataResponse.data.id !== await this.editedItem.id){
                    this.editedItem.id = await dataResponse.data.id;
                  }
                  await this.$emit('setItemRow', this.copyObjects(this.editedItem));
                  this.showBtnSave = await false;
                  this.showBtnCancel = await false;
                  this.showBtnPrint = await true;
                  this.showBtnEdit = await true;
                  // *** this.title = await this.title.substring(0,this.titleLength);
                } else (
                  type = await 'error'
                )
                message = await dataResponse.message;
                await this.$store.dispatch('loadMessage', { msg: message, type: type });
                await this.scrollToTop();
                if(await dataResponse.gotocolumn){
                  if(await dataResponse.gotocolumn.length!==0){
                    await this.getFocus(dataResponse.gotocolumn);
                  }
                }
              }
            }
          }
          
        }
        */
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.email,
            'El Campo Usuario no puede ser blanco',
            'text_email'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.firstname,
            'El Campo Nombre no puede ser blanco',
            'text_firstname'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.midname,
            'El Campo Paterno no puede ser blanco',
            'text_midname'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.active,
            'El Campo Activo no puede ser blanco',
            'select_active'
          );
        }
        return isValid;
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        await setTimeout(() => {
          this.$refs.text_find.focus();
        }, 500);
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          // *** await this.editFocus('text_email');
          await this.$refs.text_active.blur();
          await this.btnSave();
        }
      },
      processData: function () {
        //
      }
    },
    created () {
      this.editedItem = this.copyObjects(this.item);
    },
    mounted () {
      if(this.editedItem.id===0){
        // ***  this.title = this.title + ' (NUEVO REGISTRO)';
        this.showBtnEdit = false;
        this.showBtnPrint = false;
        this.showBtnSave = true;
        this.editFocus('text_email');
      }
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
<style>
  .style-chooser .vs__no-options{
    color: #ff6347;
    font-size: 20px;
  }
  .style-chooser .vs__dropdown-toggle{
    height: 50px;
    background: #dfe5fb;
    font-size: 16px;
  }
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-menu {
    background: #dfe5fb;
    font-size: 16px;
  }
  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #0000FF;
  }
</style>
